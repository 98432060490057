<script setup lang="ts">
import { useContentStore } from "@/store/content";
import { useToast } from "primevue/usetoast";
const contentStore = useContentStore();
const moveToPerscriptionWithDrug = async () => {
  await contentStore.fetchDrugs();
  const drugId = contentStore.drugsList[0]?.id;
  contentStore.drugsList = [];
  navigateTo({ path: "/prescription", query: { dev: "si", drugId } });
};

const route = useRoute();
const { sentry } = route.query;
const toast = useToast();
const { t } = useI18n();
const throwError = () => {
  toast.add({
    severity: "error",
    summary: t("error"),
    detail: "Rzucam błąd na Sentry!",
    life: 3000,
  });
  throw new Error("jest źle");
};
</script>

<template>
  <div class="index-page">
    <h1 class="mb-8">WITAJ KADULU</h1>

    <NuxtLink :to="{ path: '/declaration', query: { dev: 'yo' } }">
      <Button label="Deklaracja" class="mb-8"></Button>
    </NuxtLink>
    <NuxtLink :to="{ path: '/dismissal', query: { dev: 'ma' } }">
      <Button label="Zwolnienie" class="mb-8"></Button>
    </NuxtLink>
    <NuxtLink :to="{ path: '/prescription', query: { dev: 'ha' } }">
      <Button label="Recepta" class="mb-8"></Button>
    </NuxtLink>
    <Button label="Recepta jakimś lekiem" class="mb-8" @click="moveToPerscriptionWithDrug"></Button>
    <NuxtLink v-if="sentry" @click="throwError">
      <Button severity="danger" label="Rzuć błąd (nie używać)" class="mb-8"></Button>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.index-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $primary-bg;
  width: 100vw;
  height: 100vh;
  button {
    width: 240px;
  }
}
</style>
